(function (wpm, $, undefined) {

	wpm.getFormattedGA4Categories = function (productItems, categories) {

		let maxCategories = 5;

		// remove categories with equal names from array
		categories = Array.from(new Set(categories));

		if (Array.isArray(categories) && categories.length) {

			productItems['item_category'] = categories[0];

			let max = categories.length > maxCategories ? maxCategories : categories.length;

			for (let i = 1; i < max; i++) {
				productItems['item_category' + (i + 1)] = categories[i];
			}
		}

		return productItems;
	}

	wpm.setGoogleCidOnServer = function (targetID) {

		try {

			gtag('get', targetID, 'client_id', (clientID) => {
				// console.log('Google cid: ' + clientID);

				let cidSetOnServerCookie = 'wpm_cid_' + targetID + '_' + clientID + '_set';

				// if ((window.sessionStorage && window.sessionStorage.getItem(cidSetOnServerCookie)) || wpm.getCookie(cidSetOnServerCookie)) {
				//     return;
				// }

				// save the state in the database
				let data = {
					action: 'wpm_google_analytics_set_session_data',
					// 'nonce'    : wpm_google_premium_only_ajax_object.nonce,
					// 'nonce'    : wpm.nonce,
					target_id: targetID,
					client_id: clientID,
					referrer : wpm.getReferrerFromCookie(),
					gclid    : wpm.getClidFromBrowser('gclid'),
					// dclid    : wpm.getClidFromBrowser('dclid'),
					userAgent     : wpm.getUserAgent(),
					viewPortWidth : wpm.getViewPort().width,
					viewPortHeight: wpm.getViewPort().height,
				};

				jQuery.ajax(
					{
						type    : 'post',
						dataType: 'json',
						// url     : wpm_google_premium_only_ajax_object.ajax_url,
						url    : wpm.ajax_url,
						data   : data,
						success: function (response) {
							// console.log('cid response:');
							// console.log(response);
							// console.log(response['cid_set'])

							if (response['success'] === true) {
								if (window.sessionStorage) {
									// console.log('setting session storage');
									window.sessionStorage.setItem(cidSetOnServerCookie, JSON.stringify(true));
								} else {
									wpm.setCookie(cidSetOnServerCookie, true);
								}
							}
						},
						error  : function (response) {
							console.log(response);
						},
					});

				// wp.ajax.post('wpm_google_analytics_set_session_data', data)
				//     .done(function (response) {
				//
				//         // console.log(response);
				//
				//         if (response === 'success') {
				//             if (window.sessionStorage) {
				//                 // console.log('setting session storage');
				//                 window.sessionStorage.setItem(cidSetOnServerCookie, JSON.stringify(true));
				//             } else {
				//                 // console.log('not setting session storage');
				//
				//                 wpm.setCookie(cidSetOnServerCookie, true);
				//             }
				//         }
				//     })
				//     .fail(function (response) {
				//         console.log(response);
				//     });
			});

		} catch (e) {
			console.log(e);
		}
	}

}(window.wpm = window.wpm || {}, jQuery))
