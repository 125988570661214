// https://ads.tiktok.com/help/article?aid=10028

jQuery(function () {

	if (wpmDataLayer?.pixels?.tiktok) {

		// AddToCart event
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing TikTok ads AddToCart event');
			// console.log(product);

			if (!window.ttq) return;

			ttq.track('AddToCart', {
				content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
				content_type: 'product',
				content_name: product.name,
				quantity    : product.quantity,
				value       : product.price,
				currency    : product.currency,
			});
		});

		// InitiateCheckout event
		jQuery(document).on('wpmBeginCheckout', function (event) {

			// console.log('firing TikTok ads InitiateCheckout event');

			if (!window.ttq) return;

			ttq.track('InitiateCheckout');
		});

		// ViewContent event
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing TikTok ads VIEW_CONTENT event');
			// console.log(product);

			if (!window.ttq) return;

			ttq.track('ViewContent', {
				content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
				content_type: 'product',
				content_name: product.name,
				quantity    : product.quantity,
				value       : product.price,
				currency    : product.currency,
			});
		});

		// AddToWishlist event
		jQuery(document).on('wpmAddToWishlist', function (event, product) {

			// console.log('firing TikTok ads AddToWishlist event');
			// console.log(product);

			if (!window.ttq) return;

			ttq.track('AddToWishlist', {
				content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
				content_type: 'product',
				content_name: product.name,
				quantity    : product.quantity,
				value       : product.price,
				currency    : product.currency,
			})
		});
	}
})

jQuery(window).on('load', function () {

	if (wpmDataLayer?.pixels?.tiktok) {

		wpmExists().then(function () {

			try {
				if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

					if (!window.ttq) return;

					let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

					// console.log('ttq.track PageVisit');
					// console.log(product);

					ttq.track('ViewContent', {
						content_id  : product.dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type],
						content_type: 'product',
						content_name: product.name,
						quantity    : product.quantity,
						value       : product.price,
						currency    : product.currency,
					});

				} else if (wpmDataLayer.shop.page_type === 'search') {

					if (!window.ttq) return;

					ttq.track('Search')
				}
			} catch (e) {
				console.log(e)
			}
		})
	}
})
