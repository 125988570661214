(function (wpm, $, undefined) {

	wpm.getCartItemsGa4 = function () {

		let data = [];

		for (const [productId, product] of Object.entries(wpmDataLayer.cart)) {

			let productItems = {
				item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_name: product.name,
				quantity : product.quantity,
				// affiliation  : '',
				// coupon       : '',
				// discount     : 0,
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			data.push(productItems);
		}

		return data;
	}

}(window.wpm = window.wpm || {}, jQuery));


jQuery(function () {

	if (wpmDataLayer?.pixels?.google?.analytics?.eec && wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) {

		// view_item_list event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_item_list
		jQuery(document).on('wpmViewItemList', function (event, product) {

			// console.log('firing google select_content event');
			// console.log('firing google select_content event');
			// console.log(product);

			let productItems = {
				item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_name: product.name,
				// coupon   : "",
				// discount: 0,
				index         : product.list_position, // doesn't make sense on mini_cart
				item_list_name: wpmDataLayer.shop.list_name,
				item_list_id  : wpmDataLayer.id,
				// affiliation: "",
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
				quantity    : product.quantity,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'view_item_list', {
				send_to       : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				items         : [productItems],
				item_list_name: product.list_name, // doesn't make sense on mini_cart
				// item_list_id: product.list_id, // doesn't make sense on mini_cart
			});
		});

		// select_item event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_item
		jQuery(document).on('wpmSelectItem', function (event, product) {

			// console.log('firing google select_content event');
			// console.log('firing google select_content event');
			// console.log(product);

			let productItems = {
				item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_name: product.name,
				// coupon   : "",
				// discount: 0,
				index         : product.list_position, // doesn't make sense on mini_cart
				item_list_name: wpmDataLayer.shop.list_name,
				item_list_id  : wpmDataLayer.id,
				// affiliation: "",
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
				quantity    : product.quantity,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'select_item', {
				send_to: wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				items  : [productItems],
			});
		});

		// add_to_cart event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_to_cart
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing google add_to_cart event');
			// console.log('firing google add_to_cart event for: ' + wpmDataLayer.pixels.google.analytics.ga4.measurement_id);
			// console.log(product);

			let productItems = {
				item_name     : product.name,
				item_id       : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_list_name: wpmDataLayer.shop.list_name,
				item_list_id  : wpmDataLayer.id,
				// coupon: "",
				// discount: 0,
				// affiliation: "",
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
				quantity    : product.quantity,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'add_to_cart', {
				send_to : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				currency: wpmDataLayer.shop.currency,
				// value: 0,
				items: [productItems]
			});
		});

		// view_item event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_item
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing google view_item event');
			// console.log('firing google view_item event for: ' + wpmDataLayer.pixels.google.analytics.ga4.measurement_id);
			// console.log(product);

			let productItems = {
				item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_name: product.name,
				// coupon: "",
				// discount: 0,
				// affiliation: "",
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
				quantity    : 1,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'view_item', {
				send_to : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				currency: wpmDataLayer.shop.currency,
				// value: 0,
				items: [productItems]
			});
		});

		// add_to_wishlist event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_to_wishlist
		jQuery(document).on('wpmAddToWishlist', function (event, product) {

			// console.log('firing google add_to_wishlist event');
			// console.log('firing google add_to_wishlist event for: ' + wpmDataLayer.pixels.google.analytics.ga4.measurement_id);
			// console.log(product);

			let productItems = {
				item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_name: product.name,
				quantity : 1,
				// affiliation: "",
				// coupon: "",
				// discount: 0,
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'add_to_wishlist', {
				send_to : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				currency: wpmDataLayer.shop.currency,
				// value: 0,
				items: [productItems]
			});
		});

		// remove_from_cart event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#remove_from_cart
		jQuery(document).on('wpmRemoveFromCart', function (event, product) {

			// console.log('firing google remove_from_cart event');
			// console.log('firing google remove_from_cart event');
			// console.log(product);

			let productItems = {
				item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				item_name: product.name,
				// coupon: "",
				// discount: 0,
				// affiliation: "",
				item_brand  : product.brand,
				item_variant: product.variant,
				price       : product.price,
				currency    : wpmDataLayer.shop.currency,
				quantity    : product.quantity,
			}

			productItems = wpm.getFormattedGA4Categories(productItems, product.category);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'remove_from_cart', {
				send_to : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				currency: wpmDataLayer.shop.currency,
				// value: 0,
				items: [productItems]
			});
		});

		// begin_checkout event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#begin_checkout
		jQuery(document).on('wpmBeginCheckout', function (event) {

			// console.log('firing google begin_checkout event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'begin_checkout', {
				send_to: wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				// coupon: "",
				currency: wpmDataLayer.shop.currency,
				// value: 0,
				items: wpm.getCartItemsGa4()
			});
		});

		// view_cart event
		// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_cart
		jQuery(document).on('wpmViewCart', function (event) {

			// console.log('firing google view_cart event');
			// console.log(product);

			if (jQuery.isEmptyObject(wpmDataLayer.cart)) return;

			let products  = [];
			let cartValue = null;

			for (const [key, product] of Object.entries(wpmDataLayer.cart)) {
				// console.log(`${key}: ${value}`);

				let productItems = {
					item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
					item_name: product.name,
					quantity : product.quantity,
					// affiliation: "",
					// coupon: "",
					// discount: 0,
					// index         : product.position,
					item_brand    : product.brand,
					item_list_name: wpmDataLayer.shop.list_name,
					item_list_id  : wpmDataLayer.shop.list_id,
					item_variant  : product.variant,
					price         : product.price,
					currency      : wpmDataLayer.shop.currency,
				}

				productItems = wpm.getFormattedGA4Categories(productItems, product.category);

				products.push(productItems);

				cartValue = cartValue + product.quantity * product.price;
			}

			// console.log(products)
			// console.log('cart value: ' + cartValue.toFixed(2))

			gtag("event", "view_cart", {
				send_to : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
				currency: wpmDataLayer.shop.currency,
				value   : cartValue.toFixed(2),
				items   : products
			});
		});
	}
});

jQuery(window).on('load', function () {

	wpmExists().then(function () {

		try {
			if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

				let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

				let productItems = {
					item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
					item_name: product.name,
					// "coupon": "",
					// "discount": 0,
					// "affiliation": "",
					item_brand  : product.brand,
					item_variant: product.variant,
					price       : product.price,
					currency    : wpmDataLayer.shop.currency,
					quantity    : 1,
				}

				productItems = wpm.getFormattedGA4Categories(productItems, product.category);

				gtag("event", "view_item", {
					send_to : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
					currency: wpmDataLayer.shop.currency,
					value   : 1 * product.price,
					items   : [productItems]
				});
			} else if (wpmDataLayer.shop.page_type === 'search') {

				let products = [];

				for (const [key, product] of Object.entries(wpmDataLayer.products)) {
					// console.log(`${key}: ${value}`);

					let productItems = {
						item_id  : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						item_name: product.name,
						quantity : 1,
						// "affiliation": "",
						// "coupon": "",
						// "discount": 0,
						index         : product.position,
						item_brand    : product.brand,
						item_list_name: wpmDataLayer.shop.list_name,
						item_list_id  : wpmDataLayer.shop.list_id,
						item_variant  : product.variant,
						price         : product.price,
						currency      : wpmDataLayer.shop.currency,
					}

					productItems = wpm.getFormattedGA4Categories(productItems, product.category);

					products.push(productItems);
				}

				gtag("event", "view_search_results", {
					send_to    : wpmDataLayer.pixels.google.analytics.ga4.measurement_id,
					search_term: wpm.getSearchTermFromUrl(),
					items      : products
				});
			} else if (wpmDataLayer.shop.page_type === 'cart') {
				jQuery(document).trigger('wpmViewCart');
			}
		} catch (e) {
			console.log(e);
		}
	})
});

jQuery(window).on('load', function () {

	wpmExists().then(function () {

		try {
			if (wpmDataLayer?.pixels?.google?.analytics?.eec && wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) {

				let targetID = wpmDataLayer.pixels.google.analytics.ga4.measurement_id

				// console.log('setting cid on server: ' + targetID);

				// We need to be sure that we capture the cid early enough, because the
				// shop might be using a one click checkout button as early as on the product page.
				if (['product', 'cart', 'checkout'].indexOf(wpmDataLayer.shop.page_type) >= 0) {
					function sleep(time) {
						return new Promise((resolve) => setTimeout(resolve, time))
					}

					// The delay is necessary as we are sending the same hit through Ajax for GA UA.
					// For some reason the data cannot be written to the WC session if
					// both hits come at the same time, thus the delay is a workaround.
					sleep(500).then(() => {
						wpm.setGoogleCidOnServer(targetID)
					});

					// wpm.setGoogleCidOnServer(targetID);

				}
			}
		} catch (e) {
			console.log(e)
		}
	})
})
