(function (wpm, $, undefined) {

	wpm.getPinterestProductData = function (product) {

		// console.log(product)

		if (product.isVariation) {
			return {
				product_name      : product.name,
				product_variant_id: product.dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				// product_id        : wpmDataLayer.products[product.parentId].dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				product_id      : product.parentId_dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				product_category: product.category,
				product_variant : product.variant,
				product_price   : product.price,
				product_quantity: product.quantity,
				product_brand   : product.brand,
			}
		} else {
			return {
				product_name    : product.name,
				product_id      : product.dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				product_category: product.category,
				product_price   : product.price,
				product_quantity: product.quantity,
				product_brand   : product.brand,
			}
		}
	}

}(window.wpm = window.wpm || {}, jQuery));

jQuery(function () {

	if (wpmDataLayer?.pixels?.pinterest) {

		// AddToCart event
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing Pinterest ads AddToCart event');
			// console.log(product);

			if (!window.pintrk) return;

			pintrk("track", "addtocart", {
				"value"     : parseFloat(product.quantity * product.price),
				"currency"  : product.currency,
				"line_items": [wpm.getPinterestProductData(product)],
			});
		});

		// pageview event
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing Pinterest pageview event');
			// console.log(product);

			if (!window.pintrk) return;

			pintrk("track", "pagevisit", {
				"currency"  : product.currency,
				"line_items": [wpm.getPinterestProductData(product)],
			});
		});
	}
})

jQuery(window).on('load', function () {

	if (wpmDataLayer?.pixels?.pinterest) {

		wpmExists().then(function () {

			try {
				if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

					if (!window.pintrk) return;

					let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

					// console.log('pintrk PageVisit');
					// console.log(product);

					let productData = wpm.getPinterestProductData(product);

					// console.log(productData);

					pintrk("track", "pagevisit", {
						"currency"  : product.currency,
						"line_items": [productData],
					});

					// pintrk("track", "pagevisit");

				} else if (wpmDataLayer.shop.page_type === 'search') {

					if (!window.pintrk) return;


					let urlParams = new URLSearchParams(window.location.search);

					pintrk("track", "search", {
						"search_query": urlParams.get('s'),
					});

				} else if (wpmDataLayer.shop.page_type === 'product_category') {

					if (!window.pintrk) return;

					pintrk("track", "viewcategory");
				}
			} catch (e) {
				console.log(e);
			}
		})
	}
})
