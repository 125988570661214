(function (wpm, $, undefined) {

	wpm.getCartItemsGaUa = function () {

		let data = [];

		for (const [productId, product] of Object.entries(wpmDataLayer.cart)) {

			data.push({
				'id'      : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
				'name'    : product.name,
				'brand'   : product.brand,
				'category': product.category.join(','),
				// 'coupon': '',
				// 'list_name': '',
				// 'list_position': 1,
				'price'   : product.price,
				'quantity': product.quantity,
				'variant' : product.variant,
			});
		}

		return data;
	}

}(window.wpm = window.wpm || {}, jQuery));


jQuery(function () {

	if (wpmDataLayer?.pixels?.google?.analytics?.eec && wpmDataLayer?.pixels?.google?.analytics?.universal?.property_id) {

		// view_item_list event
		jQuery(document).on('wpmViewItemList', function (event, product) {

			// console.log('firing google select_content event');
			// console.log('firing google select_content event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'view_item_list', {
				"send_to": wpmDataLayer.pixels.google.analytics.universal.property_id,
				"items"  : [{
					"id"      : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
					"name"    : product.name,
					"brand"   : product.brand,
					"category": product.category.join(','),
					// "coupon"   : "",
					"list_name"    : wpmDataLayer.shop.list_name,
					"list_position": product.list_position, // doesn't make sense on mini_cart
					"price"        : product.price,
					"quantity"     : product.quantity,
					"variant"      : product.variant,
				}]
			});
		});

		// select_content event
		jQuery(document).on('wpmSelectContentGaUa', function (event, product) {

			// console.log('firing google select_content event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'select_content', {
				"send_to"     : wpmDataLayer.pixels.google.analytics.universal.property_id,
				"content_type": "product",
				"items"       : [
					{
						"id"           : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"         : product.name,
						"list_name"    : wpmDataLayer.shop.list_name,
						"brand"        : product.brand,
						"category"     : product.category.join(','),
						"variant"      : product.variant,
						"list_position": product.list_position, // doesn't make sense on mini_cart
						"quantity"     : product.quantity,
						"price"        : product.price
					}
				]
			});
		});

		// add_to_cart event
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing google add_to_cart event');
			// console.log('firing google add_to_cart event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'add_to_cart', {
				"send_to" : wpmDataLayer.pixels.google.analytics.universal.property_id,
				"currency": wpmDataLayer.shop.currency,
				"items"   : [
					{
						"id"           : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"         : product.name,
						"list_name"    : wpmDataLayer.shop.list_name,
						"brand"        : product.brand,
						"category"     : product.category.join(','),
						"variant"      : product.variant,
						"list_position": product.list_position, // doesn't make sense on mini_cart
						"quantity"     : product.quantity,
						"price"        : product.price
					}
				]
			});
		});

		// view_item event
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing google view_item event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'view_item', {
				"send_to": wpmDataLayer.pixels.google.analytics.universal.property_id,
				"items"  : [
					{
						"id"           : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"         : product.name,
						"list_name"    : wpmDataLayer.shop.list_name, // doesn't make sense on mini_cart
						"brand"        : product.brand,
						"category"     : product.category.join(','),
						"variant"      : product.variant,
						"list_position": 1,
						"quantity"     : 1,
						"price"        : product.price
					}
				]
			});
		});

		// add_to_wishlist event
		jQuery(document).on('wpmAddToWishlist', function (event, product) {

			// console.log('firing google add_to_wishlist event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'add_to_wishlist', {
				"send_to": wpmDataLayer.pixels.google.analytics.universal.property_id,
				"items"  : [
					{
						"id"      : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"    : product.name,
						"brand"   : product.brand,
						"category": product.category.join(','),
						// "coupon"       : "",
						"list_name"    : wpmDataLayer.shop.list_name, // doesn't make sense on mini_cart
						"list_position": 1,
						"price"        : product.price,
						"quantity"     : 1,
						"variant"      : product.variant,
					}
				]
			});
		});

		// remove_from_cart event
		jQuery(document).on('wpmRemoveFromCart', function (event, product) {

			// console.log('firing google remove_from_cart event');
			// console.log('firing google remove_from_cart event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'remove_from_cart', {
				"send_to" : wpmDataLayer.pixels.google.analytics.universal.property_id,
				"currency": wpmDataLayer.shop.currency,
				"items"   : [
					{
						"id"       : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"     : product.name,
						"list_name": wpmDataLayer.shop.list_name,
						"brand"    : product.brand,
						"category" : product.category.join(','),
						"variant"  : product.variant,
						// "list_position": product.list_position, // doesn't make sense on mini_cart
						"quantity": product.quantity,
						"price"   : product.price
					}
				]
			});
		});

		// begin_checkout event
		jQuery(document).on('wpmBeginCheckout', function (event) {

			// console.log('firing google begin_checkout event');
			// console.log(product);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'begin_checkout', {
				"send_to" : wpmDataLayer.pixels.google.analytics.universal.property_id,
				"currency": wpmDataLayer.shop.currency,
				"items"   : wpm.getCartItemsGaUa()
			});
		});

		// set_checkout_option event
		jQuery(document).on('wpmFireCheckoutOption', function (event, data) {

			// console.log('firing google set_checkout_option event');
			// console.log(data);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'set_checkout_option', {
				"send_to"        : wpmDataLayer.pixels.google.analytics.universal.property_id,
				"checkout_step"  : data.step,
				"checkout_option": data.checkout_option,
				"value"          : data.value
			});
		});

		// checkout_progress event
		jQuery(document).on('wpmFireCheckoutProgress', function (event, data) {

			// console.log('firing google checkout_progress event');
			// console.log(data);

			if (googleConfigConditionsMet('analytics')) gtag('event', 'checkout_progress', {
				"send_to"      : wpmDataLayer.pixels.google.analytics.universal.property_id,
				"checkout_step": data.step,
			});
		});
	}
});

jQuery(window).on('load', function () {

	wpmExists().then(function () {

		try {
			if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

				let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

				gtag("event", "view_item", {
					"send_to": wpmDataLayer.pixels.google.analytics.universal.property_id,
					"items"  : [{
						"id"      : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"    : product.name,
						"brand"   : product.brand,
						"category": product.category.join(','),
						// "coupon": "",
						// "list_name": "",
						// "list_position": 1,
						"price"   : product.price,
						"quantity": 1,
						"variant" : product.variant,
					}]
				});
			} else if (wpmDataLayer.shop.page_type === 'search') {

				let products = [];

				for (const [key, product] of Object.entries(wpmDataLayer.products)) {
					// console.log(`${key}: ${value}`);

					products.push({
						"id"      : product.dyn_r_ids[wpmDataLayer.pixels.google.analytics.id_type],
						"name"    : product.name,
						"quantity": 1,
						// "affiliation": "",
						// "coupon": "",
						// "discount": 0,
						"list_position": product.position,
						"brand"        : product.brand,
						"category"     : product.category.join(','),
						"list_name"    : wpmDataLayer.shop.list_name,
						"variant"      : product.variant,
						"price"        : product.price,
					});
				}

				gtag("event", "view_search_results", {
					"send_to"    : wpmDataLayer.pixels.google.analytics.universal.property_id,
					"search_term": wpm.getSearchTermFromUrl(),
					"items"      : products
				});
			}
		} catch (e) {
			console.log(e);
		}
	})
});

jQuery(window).on('load', function () {

	wpmExists().then(function () {

		try {
			if (wpmDataLayer?.pixels?.google?.analytics?.eec && wpmDataLayer?.pixels?.google?.analytics?.universal?.property_id) {

				let targetID = wpmDataLayer.pixels.google.analytics.universal.property_id;

				// We need to be sure that we capture the cid early enough, because the
				// shop might be using a one click checkout button as early as on the product page.
				if (['product', 'cart', 'checkout'].indexOf(wpmDataLayer.shop.page_type) >= 0) {
					wpm.setGoogleCidOnServer(targetID);
				}
			}
		} catch (e) {
			console.log(e);
		}
	})
})
