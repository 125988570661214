(function (wpm, $, undefined) {

	wpm.setFacebookIdentifiersOnServer = function () {

		try {

			// get first party cookies fbp and fbc

			// send the cookies to the server in order to save them on the session

			let data = {
				'action': 'wpm_facebook_set_session_identifiers',
				// 'nonce' : wpm_facebook_premium_only_ajax_object.nonce,
				'nonce': wpm.nonce,
				'fbp'  : wpm.getCookie("_fbp"),
				'fbc'  : wpm.getCookie("_fbc"),
			};

			if (data.fbp && window.sessionStorage && window.sessionStorage.getItem('wpm_fb_session_id_' + data.fbp + '_set')) {
				return;
			}

			jQuery.ajax(
				{
					type    : "post",
					dataType: "json",
					// url     : wpm_facebook_premium_only_ajax_object.ajax_url,
					url    : wpm.ajax_url,
					data   : data,
					success: function (response) {
						// console.log(response);

						if (window.sessionStorage && response['success'] === true) {
							// console.log('setting session storage');
							window.sessionStorage.setItem('wpm_fb_session_id_' + data.fbp + '_set', JSON.stringify(true));
						}
					},
					error  : function (response) {
						// console.log(response);
					},
				});

		} catch (e) {
			console.log(e);
		}
	}

}(window.wpm = window.wpm || {}, jQuery));

jQuery(function () {


	if (wpmDataLayer?.pixels?.facebook) {


		// add_to_cart event
		jQuery(document).on('wpmAddToWishlist', function (event, product) {

			// console.log('firing facebook ads AddToWishlist event');
			// console.log(data);

			fbq("track", "AddToWishlist", {
				"content_name"    : product.name,
				"content_category": product.category,
				"content_ids"     : product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
				// "contents"          : "",
				"currency": product.currency,
				"value"   : product.price,
			});
		});

		jQuery(document).on('wpmFbCapiEvent', function (event, eventData) {

			if (wpmDataLayer.pixels.facebook.capi === false) {
				return;
			}

			try {
				// save the state in the database
				let data = {
					'action': 'wpm_facebook_capi_event',
					'data'  : eventData,
					// 'nonce' : wpm_facebook_premium_only_ajax_object.nonce,
					'nonce': wpm.nonce,
				};

				jQuery.ajax(
					{
						type    : "post",
						dataType: "json",
						// url     : wpm_facebook_premium_only_ajax_object.ajax_url,
						url    : wpm.ajax_url,
						data   : data,
						success: function (msg) {
							// console.log(msg);
						},
						error  : function (msg) {
							// console.log(msg);
						}
					});
			} catch (e) {
				console.log(e);
			}
		});

	}
})

jQuery(window).on('load', function () {

	wpmExists().then(function () {

		try {
			if (wpmDataLayer?.pixels?.facebook?.pixel_id && wpmDataLayer.pixels.facebook.capi) {

				// We need to be sure that we capture the cid early enough, because the
				// shop might be using a one click checkout button as early as on the product page.
				if (['cart', 'checkout'].indexOf(wpmDataLayer.shop.page_type) >= 0) {

					wpm.setFacebookIdentifiersOnServer();
				}
			}
		} catch (e) {
			console.log(e);
		}
	})
})
