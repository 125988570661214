jQuery(function () {

	if (wpmDataLayer?.pixels?.snapchat) {

		// AddToCart event
		jQuery(document).on('wpmAddToCart', function (event, product) {

			// console.log('firing Snapchat ads ADD_CART event');
			// console.log(product);

			if (!window.snaptr) return;

			snaptr('track', 'ADD_CART', {
				'item_ids': [product.dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]],
			});
		});

		// VIEW_CONTENT event
		jQuery(document).on('wpmViewItem', function (event, product) {

			// console.log('firing Snapchat ads VIEW_CONTENT event');
			// console.log(product);

			if (!window.snaptr) return;

			snaptr('track', 'VIEW_CONTENT', {
				'item_ids': [product.dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]],
			});
		});
	}
})

jQuery(window).on('load', function () {

	if (wpmDataLayer?.pixels?.snapchat) {

		wpmExists().then(function () {

			try {
				if (wpmDataLayer.shop.page_type === 'product' && wpmDataLayer.shop.product_type !== 'variable' && wpm.getMainProductIdFromProductPage()) {

					if (!window.snaptr) return;

					let product = wpm.getProductDataForViewItemEvent(wpm.getMainProductIdFromProductPage());

					// console.log('snaptr VIEW_CONTENT');
					// console.log(product);

					snaptr('track', 'VIEW_CONTENT', {
						'item_ids': [product.dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]],
					});

				}
			} catch (e) {
				console.log(e);
			}
		})
	}
})
